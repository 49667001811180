import axios from 'axios'

const API_PREFIX = "recurrentes";

const PedidosRecurrentes = {

    listarRecurrentes: () => axios(`${API_PREFIX}/lista`),
    listarProductosRecurrentes: (id) => axios(`${API_PREFIX}/productos/${id}`),
    buscarProductosRecurrentes: (id, params) => axios(`${API_PREFIX}/buscar-productos/${id}`, { params }),
    crearRecurrente: (params) => axios.post(`${API_PREFIX}/crear`,params),
    editarRecurrente: (id, params) => axios.put(`${API_PREFIX}/editar/${id}`,params),
    eliminarRecurrente: (id) => axios.delete(`${API_PREFIX}/eliminar/${id}`),
    gestionRecurrenteProducto: (params) => axios.post(`${API_PREFIX}/gestion-recurrente-producto`,params),
    eliminarRecurrenteProducto: (id) => axios.delete(`${API_PREFIX}/eliminar-recurrente-producto/${id}`),

}

export default PedidosRecurrentes
