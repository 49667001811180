<template>
    <div class="recurrentes h-100">
        <div class="search">
            <div class="search__input-btn">
                <input type="search" name="input-pedidos" class="search__input" placeholder="Buscar pedido recurrente" />
                <el-tooltip effect="light" content="Crear pedido resumen" placement="bottom">
                    <button class="search__btn bg-leeche f-25 cr-pointer" @click="crearPedidoRecurrente">
                        +
                    </button>
                </el-tooltip>
            </div>
            <div class="list-search custom-scroll">
                <div v-for="(pedido, index) in recurrentes" :key="index" class="card-pedidos cr-pointer" @click="seleccionarPedido(pedido)">
                    <div v-if="pedidoActivo === pedido.id" class="card-pedidos__activa bg-leeche" />
                    <div class="card-pedidos__layout">
                        <div class="card-pedidos__bg bg-whitesmoke">
                            <p class="card-pedidos__tipo text-general f-17 tres-puntos">{{ pedido.nombre }}</p>
                            <div class="card-pedidos__detalles">
                                <p class="card-pedidos__vtotal f-17 text-general">{{ `${separadorNumero(pedido.subtotal)}` }}</p>
                                <p class="card-pedidos__cantidad text-general f-15"> {{ pedido.productos_count }} <span class="card-pedidos__titulo text-general f-15 ml-2 ">Productos</span></p>
                            </div>
                        </div>
                    </div>
                </div>
                <contentLoader v-show="loading" />
            </div>
        </div>
        <panel-pedidos v-if="pedidoActivo" ref="panelPedidos" :pedido-activo="pedidoActivo" @toggle="toggle" @eliminar="eliminarRecurrente" @agregarProducto="agregarProducto" @eliminarRecurrenteProdcuto="eliminarRecurrenteProdcuto" />
        <buscar-producto ref="buscarProducto" @agregarProducto="agregarProducto" />
        <modal-crear-pedido-recurrente ref="crearPedidoRecurrente" @pedidoCreado="agregarPedido" />
    </div>
</template>

<script>
import PedidosRecurrentes from '~/services/almacen/pedidos_recurrentes'

export default {
    components:{
        panelPedidos: ()=> import ('./panelPedidos'),
        buscarProducto: () => import ('./componentes/buscarProducto'),
        modalCrearPedidoRecurrente: () => import('./partials/modalCrearPedidoRecurrente'),
    },
    data(){
        return{
            loading: false,
            pedidoActivo: null,
            recurrentes:[],
        }
    },
    mounted(){
        this.listarRecurrentes()
    },
    methods:{
        toggle(){
            if(this.pedidoActivo === null){
                this.notificacion('Alerta','Por favor selecciona un recurrente','warning')
                return
            }
            this.$refs.buscarProducto.toggle(this.pedidoActivo)
        },
        crearPedidoRecurrente(titulo, pedidoSeleccionado = {}){
            console.log(pedidoSeleccionado);
            if( typeof titulo !== 'string'){
                let titulo = 'Crear pedido recurrente'
                this.$refs.crearPedidoRecurrente.toggle(titulo,pedidoSeleccionado)
            }else{
                this.$refs.crearPedidoRecurrente.toggle(titulo,pedidoSeleccionado)
            }
        },
        async listarRecurrentes(){
            try {
                this.loading = true
                const { data } = await PedidosRecurrentes.listarRecurrentes()
                this.recurrentes = data.recurrentes

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading = false
            }
        },
        agregarPedido(pedido,edito = false){
            if (edito){
                let recurrente = this.recurrentes.find(el => el.id == pedido.id)
                recurrente.nombre = pedido.nombre
            } else {
                this.recurrentes.push(pedido)
                this.pedidoActivo = null
            }
        },
        agregarProducto(pedido, herencia = 'hijo'){
            let recurrente = this.recurrentes.find(el => el.id == this.pedidoActivo)
            recurrente.subtotal = pedido.recurrente.subtotal
            recurrente.productos_count = pedido.recurrente.productos_count

            this.recurrentes = _.orderBy(this.recurrentes,'subtotal','desc')
            console.log(herencia,'aaaa');
            if (herencia=="hijo"){
                this.$refs.panelPedidos.agregarProducto(pedido)
            }
        },
        eliminarRecurrente(id){
            let i = this.recurrentes.findIndex(el => el.id == id)
            this.recurrentes.splice(i,1)
            this.pedidoActivo = null

        },
        eliminarRecurrenteProdcuto(val){
            let recurrente = this.recurrentes.find(el => el.id == this.pedidoActivo)
            recurrente.subtotal = val.recurrente.subtotal
            recurrente.productos_count = val.recurrente.productos_count
            this.recurrentes = _.orderBy(this.recurrentes,'subtotal','desc')

        },
        seleccionarPedido(pedido){
            this.pedidoActivo = pedido.id
        }
    }
}
</script>
<style lang="scss">
    .recurrentes{
         display: flex;
    }

    .search{

        border: 1px solid #dee2e6;
        padding: 10px 14px 0px 14px;
        width: 446px;
        height: calc(100vh - 138px);

        &__input-btn{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 11px;
        }

        &__input{
            width: 352px;
            height: 44px;
            border-radius: 247px;
            border: 1px solid #dee2e6;
            text-align: start;
            padding: 5px 20px;
            margin-right: 14px;
            outline: 0;
               &::placeholder{
                color: #dee2e6;
                font-size: 17px;
            }
        }
        &__btn{
            width: 40px;
            height: 40px;
            border:0;
            border-radius:10px;
            color: white;
            box-shadow: 0px 3px 6px  rgba(0,0,0,.2);
        }
    }
    .list-search{
        overflow: auto;
        height: calc(100% - 70px);
    }
    .card-pedidos{
        display: flex;
        align-items: center;
        /* margin-right: 33px; */
        &__layout{
            padding: 6px 0px 6px 6px;
            border-top: 1px solid #f8f8f8;
            width: 100%;
        }
        &__bg{
            border-radius: 12px;
            padding: 14px;
        }
        &__activa{
            height: 85px;
            border-radius: 5px;
            padding: 0 0.25rem;
            margin: 0 0.2rem 0.5rem 0px;
        }
        &__tipo{
            text-align: start;
            margin-bottom: 8px !important;
        }
        &__detalles{
            display:flex;
            align-items: center;
            justify-content: space-between;
        }
        &__vtotal{
            font-weight: 500;
        }
        &__cantidad{
            display: flex;
            align-items: center;
            font-weight: 500;
            color: #707070;
        }
        &__titulo{
            font-weight: 200;
        }
    }

/*-*-*-*-*-*-*-* Media Queries -*-*-*-*-*-*-*/

    @media(max-width:1000px){
        .search{
            width: 365px !important;
        }
    }
    @media(min-width:1000px) and (max-width : 1824px){
        .search{
            width: 400px !important;
        }
    }
    @media (max-width:768px) {
        .search{
            width: 310px !important;            
        }
    }
    
</style>
